enum FILTER_FIELD_KEY {
  ContractIds = 'ids',
  PersonIds = 'personIds',
  SupplierIds = 'supplierIds',
  ContractualRelationship = 'contractualRelationship',
  StartFrom = 'startFrom',
  StartTo = 'startTo',
  EndFrom = 'endFrom',
  EndTo = 'endTo',
  Active = 'active',
  VendorIds = 'vendorIds',
  ContractStatusIds = 'contractStatusIds',
  ContractTypeIds = 'contractTypeIds',
}

export { FILTER_FIELD_KEY }
