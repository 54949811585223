var render = function () {
  var _vm$$route$meta, _vm$state$activeContr, _vm$state$activeContr2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.contracts,
      "loading": _vm.state.isLoadingContracts,
      "row-pointer": _vm.rowPointer,
      "with-notes-and-tasks": "",
      "with-files": "",
      "with-duplicate": "",
      "elevation": _vm.elevation,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalContracts,
      "is-common-view": Boolean(!_vm.componentTableHeaders)
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onClickEdit,
      "click:duplicate": function clickDuplicate($event) {
        return _vm.listeners.onClickDuplicateContract($event);
      },
      "click:info": function clickInfo($event) {
        return _vm.listeners.onClickInfo($event);
      },
      "click:files": function clickFiles($event) {
        return _vm.listeners.onClickFiles($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onClickDelete($event);
      },
      "click:tasks": function clickTasks($event) {
        return _vm.listeners.onOpenTasksDialog($event);
      },
      "click:notes": function clickNotes($event) {
        return _vm.listeners.onOpenNotesDialog($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pb-3 ma-0",
          attrs: {
            "align": "center"
          }
        }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "text": "",
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.listeners.onToggleFilterDropdown();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e(), _c('CommonExportMenu', {
          attrs: {
            "loading": _vm.state.isLoadingExport
          },
          on: {
            "export": function _export($event) {
              return _vm.listeners.onExportContracts($event);
            }
          }
        }), _vm.functions.hasSufficientRights(_vm.constants.Rights.CONTRACT_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditContractDialogOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('contractData.contracts.actions.addContract')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('ContractsFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.CONTRACT_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, [_vm.state.isRowInfoDialogOpen ? _c('CommonInfoDialog', {
    attrs: {
      "entity": _vm.state.activeContract,
      "properties-to-show": _vm.constants.PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      "translation-key": ((_vm$$route$meta = _vm.$route.meta) === null || _vm$$route$meta === void 0 ? void 0 : _vm$$route$meta.i18nKey) || _vm.translationKey
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isRowInfoDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isRowInfoDialogOpen", $$v);
      },
      expression: "state.isRowInfoDialogOpen"
    }
  }) : _vm._e(), _vm.state.isTasksDialogOpen ? _c('CommonTasksDialog', {
    attrs: {
      "tasks": _vm.state.contractTasks,
      "entity-name": "contract",
      "entity-id": _vm.state.tasksEntityId
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.listeners.onReloadTasks();
      },
      "close": function close($event) {
        _vm.state.isTasksDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isTasksDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTasksDialogOpen", $$v);
      },
      expression: "state.isTasksDialogOpen"
    }
  }) : _vm._e(), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.contractNotes,
      "entity-name": "contract",
      "entity-id": _vm.state.notesEntityId
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": function close($event) {
        _vm.state.isNotesDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditContractDialogOpen ? _c('AddEditContractDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode,
      "contract-to-edit": _vm.state.activeContract,
      "contract-to-duplicate": _vm.state.activeContractToDuplicate
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.listeners.onAddedEditedContracts();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditContractDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditContractDialogOpen", $$v);
      },
      expression: "state.isAddEditContractDialogOpen"
    }
  }) : _vm._e(), _vm.state.isFilesDialogOpen ? _c('CommonFilesDialog', {
    attrs: {
      "entity": _vm.state.activeContract,
      "entity-id": (_vm$state$activeContr = _vm.state.activeContract) === null || _vm$state$activeContr === void 0 ? void 0 : _vm$state$activeContr.id,
      "has-download-right": _vm.functions.hasSufficientRights(_vm.constants.Rights.CONTRACT_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.CONTRACT_READ_OWN),
      "entity-name": "CONTRACT"
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isFilesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFilesDialogOpen", $$v);
      },
      expression: "state.isFilesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteContractDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteContract();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isDeleteContractDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteContractDialogOpen", $$v);
      },
      expression: "state.isDeleteContractDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('contractData.contracts.dialog.delete.text', {
    id: (_vm$state$activeContr2 = _vm.state.activeContract) === null || _vm$state$activeContr2 === void 0 ? void 0 : _vm$state$activeContr2.id
  })) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }