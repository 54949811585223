
import { computed, defineComponent, reactive, ref, PropType, watch } from '@vue/composition-api'
import { mdiPlus, mdiFilter, mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { cloneDeep, isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenu from '@/components/common/CommonExportMenu.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import { useDeleteContract, useExportContracts, useGetContracts, useGetContractsBasic } from '@/api/contract'
import { useGetSuppliersBasic } from '@/api/supplier'
import { useGetPersonsBasic } from '@/api/person'
import { useGetEnumItemsBasic } from '@/api/enumItem'

import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { dateDotNotationToDash } from '@/utils/convertDate'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { ContractOutput } from '@/api/types/contract'
import { PersonId } from '@/api/types/person'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/contractData/views/types'
import { CONTRACTUAL_RELATIONSHIP } from '@/views/types'
import { Rights } from '@/api/types/right'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'Contracts',
  components: {
    CommonTableView,
    CommonExportMenu,
    AddEditContractDialog: () => import('@/views/contractData/views/contracts/components/AddEditContractDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    ContractsFilterBar: () => import('@/views/contractData/views/contracts/components/ContractsFilterBar.vue'),
    CommonFilesDialog: () => import('@/components/common/CommonFilesDialog.vue'),
  },
  props: {
    componentTableHeaders: {
      type: Array,
      default: () => [],
    },
    filterById: {
      type: Number as PropType<PersonId>,
      default: null,
    },
    withoutFilterPersistence: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 2,
    },
    rowPointer: {
      type: Boolean,
      default: false,
    },
    translationKey: {
      type: String,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const VIEW_TABLE_HEADERS = [
      {
        text: root.$t('contractData.contracts.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.contractualRelationship'),
        value: 'contractualRelationship',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.supplier'),
        value: 'supplier.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.vendor'),
        value: 'vendor.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.contractStart'),
        value: 'start',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.contractEnd'),
        value: 'end',
        sortable: true,
      },
      hasSufficientRights(Rights.FINANCIAL_READ) && {
        text: root.$t('contractData.contracts.table.col.title.hourlyRate'),
        value: 'hourlyRate',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.scope'),
        value: 'scope',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.person'),
        value: 'person.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.filesCount'),
        value: 'filesCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.active'),
        value: 'active',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const TABLE_HEADERS = props.componentTableHeaders.length ? props.componentTableHeaders : VIEW_TABLE_HEADERS

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof ContractOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'dailyWorkingTime',
    ]

    const isAddEditContractDialogOpen = ref(false)

    const isEditMode = ref(false)

    const activeContract = ref<ContractOutput | null>(null)
    const activeContractToDuplicate = ref<ContractOutput | null>(null)

    function onClickEdit(_, { item: contract }: { item: ContractOutput }): void {
      if (hasSufficientRights(Rights.CONTRACT_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isEditMode.value = true

        isAddEditContractDialogOpen.value = true

        activeContract.value = { ...contract }
      }
    }

    const isDeleteContractDialogOpen = ref(false)

    const { deleteContract } = useDeleteContract()

    async function onClickDelete(contract: ContractOutput): Promise<void> {
      activeContract.value = { ...contract }

      isDeleteContractDialogOpen.value = true
    }

    async function onDeleteContract(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteContract(activeContract.value!.id)
        emit('reload')
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteContractDialogOpen.value = false

      activeContract.value = null

      debouncedCb.value()
    }

    const isRowInfoDialogOpen = ref(false)

    const isFilesDialogOpen = ref(false)

    function onClickDuplicateContract(contract: ContractOutput): void {
      isAddEditContractDialogOpen.value = true
      let updatedForm = deleteId({ ...contract })

      activeContractToDuplicate.value = updatedForm
    }

    function deleteId<T extends object>(form: T): T {
      const updatedForm = cloneDeep(form)

      Object.entries(updatedForm).forEach(() => {
        delete updatedForm['id']
      })

      return updatedForm
    }

    function onClickInfo(contract: ContractOutput): void {
      activeContract.value = { ...contract }

      isRowInfoDialogOpen.value = true
    }

    function onClickFiles(contract: ContractOutput): void {
      activeContract.value = { ...contract }

      isFilesDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoFilesDialog(): void {
      isAddEditContractDialogOpen.value = false

      isDeleteContractDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isFilesDialogOpen.value = false

      isEditMode.value = false

      activeContract.value = null
      activeContractToDuplicate.value = null
    }

    const { exec: getSuppliersBasic, data: suppliers, isLoading: isLoadingSuppliersBasic } = useGetSuppliersBasic()

    const { exec: getPersonsBasic, data: persons, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()

    const {
      exec: getContractsBasic,
      data: contractsBasic,
      isLoading: isLoadingContractsBasic,
    } = useGetContractsBasic()

    const {
      exec: getEnumItemContractStatus,
      data: enumItemsContractStatus,
      isLoading: isLoadingEnumItemsContractStatus,
    } = useGetEnumItemsBasic()

    const {
      exec: getEnumItemContractType,
      data: enumItemsContractType,
      isLoading: isLoadingEnumItemsContractType,
    } = useGetEnumItemsBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !suppliers.value?.length ||
          !persons.value?.length ||
          !enumItemsContractStatus.value?.length ||
          !enumItemsContractType.value?.length ||
          !contractsBasic.value?.length
        ) {
          getSuppliersBasic()
          getPersonsBasic()
          getEnumItemContractStatus({ params: { enumItemAssignment: 'CONTRACT_STATUS' } })
          getEnumItemContractType({ params: { enumItemAssignment: 'CONTRACT_TYPE' } })
          getContractsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.ContractIds,
        value: null,
        label: root.$t('contractData.contracts.filters.contractIds'),
        items: computed(() => contractsBasic.value ?? []),
        isLoading: computed(() => isLoadingContractsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.PersonIds,
        value: null,
        label: root.$t('contractData.contracts.filters.persons'),
        items: computed(() => persons.value ?? []),
        isLoading: computed(() => isLoadingPersonsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.SupplierIds,
        value: null,
        label: root.$t('contractData.contracts.filters.supplierIds'),
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ContractualRelationship,
        value: null,
        label: root.$t('contractData.contracts.filters.contractualRelationships'),
        items: computed(() => CONTRACTUAL_RELATIONSHIP),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StartFrom,
        value: null,
        label: root.$t('contractData.contracts.filters.startFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.EndFrom,
        value: null,
        label: root.$t('contractData.contracts.filters.endFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.StartTo,
        value: null,
        label: root.$t('contractData.contracts.filters.startTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.EndTo,
        value: null,
        label: root.$t('contractData.contracts.filters.endTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.VendorIds,
        value: null,
        label: root.$t('contractData.contracts.filters.vendorIds'),
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ContractStatusIds,
        value: null,
        label: root.$t('contractData.contracts.filters.contractStatusIds'),
        items: computed(() => enumItemsContractStatus.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsContractStatus.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ContractTypeIds,
        value: null,
        label: root.$t('contractData.contracts.filters.contractTypeIds'),
        items: computed(() => enumItemsContractType.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsContractType.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Active,
        value: null,
        label: root.$t('contractData.contracts.filters.active'),
        items: [],
        dataTyp: FilterDataTypes.BooleanDate,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init,
      props.withoutFilterPersistence
    )

    setSort(vuetifyTableOptions, { by: 'start', desc: true })

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    const {
      exec: getContracts,
      data: contracts,
      isLoading: isLoadingContracts,
      paginationResponse,
    } = useGetContracts()

    const totalContracts = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      if (props.filterById) {
        await getContracts({
          params: { ...paginationParams.value, ...filterFieldsObject, personIds: props.filterById },
        })
      } else {
        await getContracts({ params: { ...paginationParams.value, ...filterFieldsObject } })
      }
    }

    const { exportContracts, isLoading: isLoadingExport } = useExportContracts()

    async function onExportContracts(dateRange: { startMonth: string; endMonth: string }) {
      try {
        await exportContracts({
          ...filterFieldsObject.value,
          personIds: props.filterById ? props.filterById : null,
          startMonth: dateDotNotationToDash(dateRange.startMonth),
          endMonth: dateDotNotationToDash(dateRange.endMonth),
        })

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onAddedEditedContracts() {
      debouncedCb.value()
      emit('reload-person')
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: contractNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<ContractOutput>(contracts, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: contractTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<ContractOutput>(contracts, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiPlus,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        contracts,
        isLoadingContracts,

        isAddEditContractDialogOpen,
        isEditMode,
        activeContract,
        activeContractToDuplicate,

        isDeleteContractDialogOpen,

        isRowInfoDialogOpen,

        isTasksDialogOpen,
        contractTasks,
        tasksEntityId,

        isNotesDialogOpen,
        contractNotes,
        notesEntityId,

        isFilterDropdownOpen,
        vuetifyTableOptions,
        totalContracts,

        isLoadingExport,

        isFilesDialogOpen,
      },
      listeners: {
        onClickEdit,
        onClickDuplicateContract,

        onClickDelete,
        onDeleteContract,

        onClickInfo,

        onCloseAddEditDeleteInfoFilesDialog,
        onAddedEditedContracts,

        onOpenTasksDialog,
        onReloadTasks,

        onOpenNotesDialog,
        onReloadNotes,

        onToggleFilterDropdown,

        onExportContracts,

        onClickFiles,
      },
      functions: {
        debouncedCb,

        hasSufficientRights,
      },
    })
  },
})
